:root {
    --primary-color: #C80000;
    --secondary-color: #000;
    --padding-size: 1.5rem;
    --bs-table-bg: #FAB217 !important;
}

.SEOtitle {
    font-family: 'Lexend', sans-serif !important;
    color: black !important;
    text-transform: none !important;
    font-weight: 700 !important;
    font-size: 26px !important;
}

.SEOSubtitle {
    font-family: 'Lexend', sans-serif !important;
    color: black !important;
    text-transform: none !important;
    font-weight: 700 !important;
    font-size: 18px !important;
}

.SEODescrpition {
    font-family: 'Inter', sans-serif;
    font-size: 1rem !important;
}

.SEOcard {
    /* border: 1px solid var(--secondary-color) !important; */
    border-radius: 1rem !important;
}

.SEOMultiPage h2,
h3 {
    font-family: 'Lexend', sans-serif !important;
    font-weight: 700 !important;
    font-size: 26px !important;
}

/* Add this CSS in your App or a separate CSS file */
.spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


h2 {
    color: #333;
    text-align: center;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    position: relative;
    margin: 25px 0 50px;
}

/* h2::after {
    content: "";
    width: 100px;
    position: absolute;
    margin: 0 auto;
    height: 3px;
    background: #ffdc12;
    left: 0;
    right: 0;
    bottom: -10px;
} */

.carousel {
    width: 650px;
    margin: 0 auto;
    padding-bottom: 50px;
    background-color: var(--primary-color);
}

.carousel-control-prev-icon,
.carousel-control-next-icon,
.carousel-indicators {
    background: none !important;
}

.carousel .carousel-item {
    color: #999;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    /* min-height: 340px; */
}

.carousel .carousel-item a {
    color: #eb7245;
}

.carousel .img-box {
    width: 145px;
    height: 145px;
    margin: 0 auto;
    border-radius: 50%;
}

.carousel .img-box img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
}

.carousel .testimonial {
    padding: 30px 0 10px;
    color: black;
}

.carousel .overview {
    text-align: center;
    padding-bottom: 5px;
}

.carousel .overview b {
    color: #333;
    font-size: 15px;
    text-transform: uppercase;
    display: block;
    padding-bottom: 5px;
}

.carousel .star-rating i {
    font-size: 18px;
    color: #ffdc12;
}

.carousel-control-prev,
.carousel-control-next {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #999;
    text-shadow: none;
    top: 4px;
}

.carousel-control-prev i,
.carousel-control-next i {
    font-size: 20px;
    margin-right: 2px;
}

.carousel-control-prev {
    left: auto;
    right: 40px;
}

.carousel-control-next i {
    margin-right: -2px;
}



.carousel-indicators li,
.carousel-indicators li.active {
    width: 11px;
    height: 11px;
    margin: 1px 5px;
    border-radius: 50%;
}

.carousel-indicators li {
    background: black;
    border: none;
}

.carousel-indicators li.active {
    background: #888;
}

.linkStyle,
.linkStyle a {
    color: black !important;
    font-weight: bold !important;
}

.seoimgbanner {
    width: 100%;
    height: 100%;
}

/* .seoback {
    background: rgb(0, 196, 204);
    background: linear-gradient(90deg, rgba(0, 196, 204, 1) 35%, rgba(124, 42, 232, 1) 100%);
} */

.CityHeading {
    text-transform: capitalize;
}


.droptaxiImg {
    height: 243px;
}


.img-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: all .25s ease;
}

.pdtlink-btn {
    border-radius: 20px;
    background-color: var(--primary-color);
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    color: #fff;
    outline: 0;
    outline-offset: 0;
    white-space: nowrap;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.pdtlink-btn:hover {
    border-radius: 20px;
    background-color: var(--secondary-color);
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    color: #fff;
    outline: 0;
    outline-offset: 0;
    transform: scale(1.05);
}

.pdt-btn {
    border-radius: 20px;
    background-color: var(--primary-color);
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    padding: 0 25px;
    color: #fff;
    outline: 0;
    outline-offset: 0;
    white-space: nowrap;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.pdt-btn:hover {
    border-radius: 20px;
    background-color: var(--secondary-color);
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    padding: 0 25px;
    color: #fff;
    outline: 0;
    outline-offset: 0;
    transform: scale(1.05);
}

.pdt-btn a {
    color: #fff !important;
}
.card {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    /* border: 1px solid rgba(0, 0, 0, .125); */
    border-radius: .25rem;
    margin-bottom: 20px !important;
}

/* subtle zoom to attention and then back */
.pop-outin {
    animation: 2s anim-popoutin ease infinite;
}

@keyframes anim-popoutin {
    0% {
        color: black;
        transform: scale(0);
        opacity: 0;
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    25% {
        color: #c80000;
        transform: scale(2);
        opacity: 1;
        text-shadow: 1px 1px 1px #c80000;
    }

    50% {
        color: black;
        transform: scale(1);
        opacity: 1;
        text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(1);
        opacity: 1;
        text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
}

.textCol-secondary {
    /* color: var(--secondary-color); */
    font-weight: 700 !important;
    font-size: 26px !important;
}

.SEOTable th,
.SEOTable td {
    background-color: white !important;
    color: #000;
    padding: 10px;
    text-align: center;
    border: 1px solid;
}

.FamousTextList {
    font-size: 16px;
    text-align: start !important;
    line-height: 30px !important;
}